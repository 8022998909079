<template>
  <app-tasks
    :tabs="conditions"
    @refresh-request="refresh"
    :tasks="tasks"
    :count="count"
    :pages="pages"
  ></app-tasks>
</template>

<script>
import TaskService from "@/services/task.service"

export default {
  name: "Interventions",
  data() {
    return {
      tasks: [],
      pages: 0,
      count: 0
    }
  },
  computed: {
    conditions() {
      return TaskService.getPatrolConditions()
    }
  },
  methods: {
    refresh(context) {
      TaskService.fetchTasks(
        context.query
      ).then(data => {
        this.tasks = []
        for (const task of data.results) {
          this.updateLastUpdatedTask(task)
        }
        this.pages = data.pages
        this.count = data.count
        this.tasks = data.results
      })
    },
    updateLastUpdatedTask(task) {
      if (task.modified_at > this.lastUpdatedTask) {
        this.lastUpdatedTask = task.modified_at
        console.log("last task", task, task.modified_at)
      }
    },
  }
}
</script>
